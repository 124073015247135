
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as VM from "@/viewModel";

@Options({
    components: {
    }
})
export default class ChooseEditActionModal extends Vue {

    @Prop() callback: (action: VM.EditPhotoAction) => void;
    @Prop() hideDeleteOption: boolean = false;

    actions = VM.EditPhotoAction;

    created(){
        
    }

}
