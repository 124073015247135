
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';
import store from '@/store';
import UploadPhotoModal from '../modals/uploadPhotoModal.vue';
import GetPhotoModal from '../modals/getPhotoModal.vue';
import createOrEditQAModal from '../modals/editQAModal/createOrEditQAModal.vue';
import { createPreviewFromImage } from '@/utils';
import { AppUserClient } from '@/services/Services';
import { CommonServices } from '@/services/CommonServices';
import * as VM from "@/viewModel";
import ChooseEditActionModal from '../modals/chooseEditActionModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import { StorageServices } from '@/services/StorageServices';

@Options({})
export default class ProfileImageHandle extends Vue {

    @Prop() noBlurQuestionImageUrls : string[];
    @Prop() questions : OM.ProfileQuestion[];
    @Prop() profile: OM.ProfileEditVM;
    
    questionNumber: number = store.state.consts.config.questions.questionsNumber;

    created(){
    }

    get finalQuestions(){
        var temp = JSON.parse(JSON.stringify(this.questions));

        var difference = this.questionNumber - temp.length;
        for(var i = 0; i < difference; i++)
            temp.push(new OM.ProfileQuestion());

        return temp;
    }

    changeQuestion(question: OM.ProfileQuestion, index: number){
        if(index < this.questions.length)
            this.editQuestion(question, index);
        else 
            this.createQuestion(question, index);
    }

    reload(){
        this.$emit("reload")
    }

    async createQuestion(question: OM.ProfileQuestion, index: number){
        var file = await this.getPhoto();
        var imagenoblur = await createPreviewFromImage(file)
        var questionAnswers = await this.getQA(question, imagenoblur, index);

        var vm = new OM.UploadProfileQuestionVM();
        vm.profileQuestion = questionAnswers;
        vm.questionIndex = index;

        this.uploadNewQuestion(vm, file);
    }

    uploadNewQuestion(vm: OM.UploadProfileQuestionVM, file: File){
        CommonServices.showSpinner();
        AppUserClient.updateProfileQuestion(vm)
        .then( x => {
            AppUserClient.updateProfileQuestionImage(x, file)
            .then( x => {
                //x indica se si deve refreshare il token
                if(!x){
                    CommonServices.hideSpinner();
                    this.checkProfileCompleted();
                    return;
                }

                AppUserClient.tokenUpgrade()
                .then( x => {
                    StorageServices.setNewTokens(x.token, x.refreshToken);
                    this.checkProfileCompleted();
                }).catch( err => {
                    this.checkProfileCompleted();
                })
                .finally( () => {
                    CommonServices.hideSpinner();
                })
            }).catch(err => {
                CommonServices.hideSpinner();
                this.$opModal.show(InfoModal, {
                    img: 'face_error.svg',
                    text: err.Message,
                    confirm: this.$localizationService.getLocalizedValue("app_Riprova", "Riprova"),
                    confirmCb: () => {
                        this.$opModal.closeLast();
                        this.uploadNewQuestion(vm, file);
                    },
                    deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                    denyCb: () => {
                        this.$opModal.closeLast();
                        this.checkProfileCompleted();
                    },
                })
            })
        })
        .catch(err => {
            CommonServices.hideSpinner();
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeAll();
                }
            })
        })
    }

    async editQuestion(question: OM.ProfileQuestion, index: number){
        var action = await this.getAction();

        if(action == VM.EditPhotoAction.changephoto){
            this.changePhoto(index);
            return;
        }
        else if(action == VM.EditPhotoAction.editqa){
            this.changeQA(question, index);
            return;
        }

        this.deleteQuestion(index);
    }


    async changePhoto(index: number){
        var file = await this.getPhoto();
        
        this.updateProfileQuestionImage(index, file);
    }

    updateProfileQuestionImage(index: number, file: File){
        CommonServices.showSpinner();

        AppUserClient.updateProfileQuestionImage(index, file)
        .then( x => {
            //x indica se si deve refreshare il token
            if(!x){
                this.reload();
                CommonServices.hideSpinner();
                return;
            }

            AppUserClient.tokenUpgrade()
            .then( x => {
                StorageServices.setNewTokens(x.token, x.refreshToken);
                this.reload();
            }).catch( err => {
                this.reload();
            })
            .finally( () => {
                CommonServices.hideSpinner();
            })

        }).catch(err => {
            CommonServices.hideSpinner();
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Riprova", "Riprova"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                    this.updateProfileQuestionImage(index, file);
                },
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                },
            })
        })
    }

    async changeQA(question: OM.ProfileQuestion, index: number){
        var qa = await this.getQA(question, this.noBlurQuestionImageUrls[index], index);

        var vm = new OM.UploadProfileQuestionVM();
        vm.profileQuestion = qa;
        vm.questionIndex = index;

        AppUserClient.updateProfileQuestion(vm)
        .then( x => {
            this.$opModal.closeLast();
            this.reload();
        }).catch(err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeAll();
                }
            })
        })
    }

    deleteQuestion(index: number){
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_ImageQuestionAndAnswersWillBeDeletedAreYouSure?", "Image, question and answers will be deleted. Are you sure?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_No", "No"),
            confirmCb: () => {
                AppUserClient.deleteProfileQuestion(index)
                .then( x => {
                    this.checkProfileCompleted();
                }).catch( err => {
                    this.$opModal.show(InfoModal, {
                        img: "face_error.svg",
                        text: err.Message,
                        confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                        confirmCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            },
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    getPhoto() : Promise<File> {
        let prom = new Promise<File>((resolve, reject) => {
            this.$opModal.show(GetPhotoModal, {
                callback: (file: File) => {
                    resolve(file);
                    this.$opModal.closeLast();
                }
            })
        });

        return prom;
    }

    async getQA(question: OM.ProfileQuestion, imagenoblur: string, index: number) : Promise<OM.ProfileQuestion> {
        let prom = new Promise<OM.ProfileQuestion>((resolve, reject) => {
            var match = this.getTempMatch();

            this.$opModal.show(createOrEditQAModal, {
                otherQuestion: this.questions,
                noBlurQuestionImageUrls: this.noBlurQuestionImageUrls,
                index: index,
                question: question,
                imageNotBlur: imagenoblur,
                match: match,
                callback: (questionAnswers: OM.ProfileQuestion) => {
                    resolve(questionAnswers);
                }
            })
        });

        return prom;
    }

    getTempMatch(){
        var match = new OM.MatchDetailVm();
        match.birthDate = this.profile.personalData.birthDate;
        match.name = this.profile.personalData.name;
        match.sexualOrientation = this.profile.profileData.sexualOrientation;
        match.minimumAffinityRate = this.profile.profileData.minimumAffinityPercentage;

        return match;
    }

    getAction() : Promise<VM.EditPhotoAction> {
        let prom = new Promise<VM.EditPhotoAction>((resolve, reject) => {
            this.$opModal.show(ChooseEditActionModal, {
                hideDeleteOption: store.state.loggedUser.profileCompleted && ((this.questions.length - 1) < store.state.consts.config.questions.minimumRequiredQuestionsNumber),
                callback: (action: VM.EditPhotoAction) => {
                    resolve(action);
                    this.$opModal.closeLast();
                }
            })
        });

        return prom;
    }

    checkProfileCompleted() {
        AppUserClient.checkIfProfileCompleted()
        .then(x => {
            this.$store.state.loggedUser.profileCompleted = x;
            this.$opModal.closeLast();
            this.reload();
        })
    }

}
