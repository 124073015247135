
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppUserClient } from '@/services/Services';
import writeQuestion from './components/writeQuestion.vue'
import writeAnswer from './components/writeAnswer.vue'
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from "@/Model";

@Options({
    components: {
        writeQuestion,
        writeAnswer,
    }
})
export default class EditQAModal extends Vue {

    @Prop() otherQuestion: OM.ProfileQuestion[];
    @Prop() noBlurQuestionImageUrls: string[];
    @Prop() index: number;
    @Prop() question: OM.ProfileQuestion;
    @Prop() imageNotBlur: string;
    @Prop() callback: (question: OM.ProfileQuestion) => void;
    @Prop() match: OM.MatchDetailVm;

    step: number = 1;
    localQuestion: OM.ProfileQuestion = new OM.ProfileQuestion();
    myImages: string[] = [];

    created(){
        this.loadImages();
        this.localQuestion = JSON.parse(JSON.stringify(this.question));
        var difference = 4 - this.localQuestion.answers.length;
        for(var i = 0; i < difference; i++)
            this.localQuestion.answers.push("");
    }

    get imageToUpload(){
        if(this.imageNotBlur)
            return this.imageNotBlur;
        
        return "";
    }

    get image(){
        var photoIndex = this.index * 4 + this.step - 1;
        if(this.myImages.length == 0 || !this.myImages[photoIndex])
            return this.imageNotBlur;

        return this.myImages[photoIndex];
    }
    
    get answerImage(){
        var photoIndex = this.index * 4 + this.step - 2;

        if(this.myImages.length == 0 || !this.myImages[photoIndex])
            return this.imageNotBlur;

        return this.myImages[photoIndex];
    }

    get isAnswerImageBlurred(){
        var photoIndex = this.index * 4 + this.step - 2;

        if(this.myImages.length == 0 || !this.myImages[photoIndex])
            return false;

        return true;
    }

    get isDisabled(){
        if(this.step == 1 && !this.localQuestion.question.trim())
            return true;
        else if(this.step == 1)
            return false;

        if(this.step > 1 && this.step < 6 && !this.localQuestion.answers[this.step - 2].trim())
            return true;
        else if(this.step > 1 && this.step < 6)
            return false;

        return false;
    }

    async loadImages(){
        this.myImages = await AppUserClient.getMyImagesWithAllPercentage(false);
    }

    async next(){
        if(this.step < 6){
            if(this.step == 4)
                this.loadImages();

            this.step ++;
            return;
        }

        var vm = new OM.UploadProfileQuestionVM();
        vm.profileQuestion = this.localQuestion;
        vm.questionIndex = this.index;

        AppUserClient.canUpdateProfileQuestion(vm, false)
        .then( x => {
            this.callback(this.localQuestion);
        }).catch(err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    close(){
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_UscendoPerderaiLaDomandaELeRisposteVuoiUscire?", "Uscendo perderai la domanda e le risposte. <br />Vuoi uscire?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_No", "No"),
            confirmCb: () => {
                this.$opModal.closeAll();
            },
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    get allQuestions(){
        var result = JSON.parse(JSON.stringify(this.otherQuestion));

        if(this.index >= this.otherQuestion.length)
            result.push(this.localQuestion)
        else
            result[this.index] = this.localQuestion;

        return result;
    }

    get allNoBlurQuestionImageUrls(){
        var result = JSON.parse(JSON.stringify(this.noBlurQuestionImageUrls));

        if(this.index >= this.otherQuestion.length)
            result.push(this.imageNotBlur)
        else
            result[this.index] = this.imageNotBlur;

        return result;
    }

    getPreviewImages(index: number){
        if(this.myImages.length == 0 && this.imageToUpload.length == 0)
            return [];

        var photoIndex = index * 4;

        if(!this.myImages[photoIndex]){
            var result = [];
            result.push(this.imageToUpload)
            result.push(this.imageToUpload)
            result.push(this.imageToUpload)
            result.push(this.imageToUpload)

            return result;
        }

        return this.myImages.slice(photoIndex, index * 4 + 4);
    }

    overrideBomb() {
        this.otherQuestion.forEach(element => {
            element.minPercentage = null;
        });
    }
}
