
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";
import { AppUserClient, PremiumToClaimClient } from '@/services/Services';
import premiumToClaimComponent from './premiumToClaimComponent.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';

@Options({
    components: {
        premiumToClaimComponent
    }
})
export default class myInvitationModal extends Vue {

    @Prop() callback: any;
    @Prop() referralCode: string;

    list: OM.PremiumToClaimVM[] = [];

    created() {
        this.init();
    }

    init(){
        PremiumToClaimClient.getMyItems()
        .then( x => {
            this.list = x;
        })
    }

    claimItem(item: OM.PremiumToClaimVM){
        if(item.claimedOn)
            return;

        CommonServices.showSpinner();
        PremiumToClaimClient.claimItem(item.identifier)
        .then( x => {
            AppUserClient.tokenUpgrade()
            .then( x => {
                StorageServices.setNewTokens(x.token, x.refreshToken);
                this.init();
            }).catch( err => {
                this.init();
            }).finally( () => {
                CommonServices.hideSpinner();
            })
        }).catch( err => {
            CommonServices.hideSpinner();
            this.$opModal.show(InfoModal, {
                img: "face_error.svg",
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

}
