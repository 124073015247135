
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { removeSpaces } from '@/utils';
import WithModel from '@/components/standard/formFieldPlugin/mixins/withModel';

@Options({
    components: {
    }
})
export default class ReasonModal extends mixins(WithModel) {
}
