
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { AppUserClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import { StorageServices } from '@/services/StorageServices';
import PersonalDataModal from './personalDataModal.vue';
import NotificationsSettingsModal from './notificationsSettingsModal.vue';
import MyEventsModal from './myEventsModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import store from '@/store';
import * as OM from "@/Model";
import myInvitationModal from './myInvitationModal.vue';

@Options({
    components: {
    }
})
export default class SettingsModal extends Vue {

    @Prop() reload: () => void;

    profile: OM.ProfileEditVM = new OM.ProfileEditVM();
    languageSelected: string = store.state.loggedUser.preferredCulture;
    languageList: OM.TextIdentifier[] = [];

    created() {
        this.init();
        this.languageList = this.$localizationService.getEnabledLanguageList();
    }

    init() {
        AppUserClient.getProfileEdit()
        .then(x => {
            this.profile = x;
        })
    }

    @Watch('languageSelected')
    changeLanguage() {
        AppUserClient.changeLanguageAndGetToken(this.languageSelected)
        .then(x => {
            var token = x;

            StorageServices.setNewTokens(token, store.state.loggedUser.refreshToken);

            var loggedUser = store.state.loggedUser;
            loggedUser.preferredCulture = this.languageSelected;

            StorageServices.setLoggedUser(loggedUser);
            store.state.selectedLanguage = this.languageSelected;
            
            this.reload();
        })
    }

    openPersonalDataModal() {
        this.$opModal.show(PersonalDataModal, {
            profile: this.profile,
            callback: () => {
                this.init();
                this.$opModal.closeLast();
            }
        })
    }

    openNotificationsSettingsModal() {
        this.$opModal.show(NotificationsSettingsModal, {
            profile: this.profile,
            callback: () => {
                this.init();
                this.$opModal.closeLast();
            }
        })
    }

    openMyInvitations() {
        this.$opModal.show(myInvitationModal, {
            referralCode: this.profile.referralCode,
            callback: () => {
            }
        })
    }

    logout(){
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_SeiSicuroDiVolerEffettuareIlLogout?", "Sei sicuro di voler effettuare il logout?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_No", "No"),
            confirmCb: () => {
                LoginServices.logout()
                .then( x => {
                    this.$opModal.closeAll();
                    this.$router.push("/");
                })
            },
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    closeSettingsModal() {
        this.$opModal.closeLast();
        this.reload();
    }
}
