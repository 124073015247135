
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from "@/Model";
import { createPreviewFromImage, getFileFromUri } from '@/utils';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({
    components: {
    }
})
export default class GetPhotoModal extends Vue {

    @Prop() callback: (file: File) => void;
    isCordova: boolean = false;

    created(){
        if(window.cordova)
            this.isCordova = true;
    }

    clickInput(){
        (<any>this.$refs.input).click()
    }

    getPicture(from: number){
        var view = this;
        let onSuccess = (imgData: any) => {
            StatusBar.hide();
            StatusBar.show();
            // this.callback(imgData);

            getFileFromUri(imgData)
            .then( file => {
                view.callback(file);
            });
        }

        let onError = (err: any) => {
            StatusBar.hide();
            StatusBar.show();

            if(typeof err == typeof "")
                return;

            var title = this.$localizationService.getLocalizedValue("app_FevenNecessitaDellAccessoAllaTuaGalleria", "Feven necessita dell'accesso alla tua galleria");
            var text = this.$localizationService.getLocalizedValue("app_VaiNelleImpostazioniDelTuoDispositivoEConcediLAccessoAllaTuaGalleria", "Vai nelle impostazioni del tuo dispositivo e concedi l'accesso alla tua galleria a Feven");

            if(from == 1){
                title = this.$localizationService.getLocalizedValue("app_FevenNecessitaDellAccessoAllaTuaFotocamera", "Feven necessita dell'accesso alla tua fotocamera");
                text = this.$localizationService.getLocalizedValue("app_VaiNelleImpostazioniDelTuoDispositivoEConcediLAccessoAllaTuaFotocamera", "Vai nelle impostazioni del tuo dispositivo e concedi l'accesso alla tua fotocamera a Feven");
            }

            this.$opModal.show(InfoModal, {
                img: 'camera_error.svg',
                text: title,
                subtitle: text,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        }
        
        (<any>window).navigator.camera.getPicture(onSuccess, onError, {
            destinationType: 1,
            sourceType: from,
            correctOrientation: true,
            allowEdit: false
        })
    }

    onFileSelect(event: any){
        let file = event.target.files[0];
        this.callback(file);
    }
}
