
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppUserClient, DeleteAccountReasonClient } from '@/services/Services';
import store from '@/store';
import * as OM from "@/Model";
import InfoModal from '@/components/modals/infoModal.vue';
import { LoginServices } from '@/services/LoginServices';
import DeleteAccountModal from '../DeleteAccount/deleteAccountModal.vue';
import DeleteAccountInfoModal from '../DeleteAccount/deleteAccountInfoModal.vue';
import { CommonServices } from '@/services/CommonServices';

@Options({
    components: {}
})
export default class PersonalDataModal extends Vue {

    @Prop() callback: any;
    @Prop() profile: OM.ProfileEditVM;

    settings: OM.UpdateSettingsVM = new OM.UpdateSettingsVM();
    sexualOrientations: OM.TextIdentifier[] = [];

    created() {
        this.sexualOrientations = store.state.consts.sexualOrientation;
        this.settings = {
            gender: this.profile.profileData.gender,
            showAge: this.profile.profileData.showAge,
            emailAddress: this.profile.emailAddress,
            mailing: this.profile.mailNotification,
            notification: this.profile.smsNotification,
            showSexualOrientation: this.profile.profileData.showSexualOrientation,
            sexualOrientation: this.profile.profileData.sexualOrientation,
            experienceCategoriesNotification: this.profile.experienceCategoriesNotificationIds
        };
    }

    save() {
        AppUserClient.updateSettings(this.settings)
        .then( x => {
            this.callback();
        }).catch(err => {
            this.$opModal.show(InfoModal, {
                img: "face_error.svg",
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    setGender(gender: string){
        this.settings.gender = Number.parseInt(gender);
    }

    deleteAccountInfo(){
        this.$opModal.show(DeleteAccountInfoModal, {
            next: () => {
                this.$opModal.closeLast();
                this.deleteAccountSteps();
            }
        })
    }

    deleteAccountSteps(){
        this.$opModal.show(DeleteAccountModal, {
            deleteAccount: (model: OM.DeleteAccountReason) => {
                this.sendReason(model);
            }
        })
    }

    sendReason(model: OM.DeleteAccountReason){
        CommonServices.showSpinner();
        DeleteAccountReasonClient.create(model)
        .then( x => {
            this.deleteAccount();
        }).catch(err => {
            CommonServices.hideSpinner();
            this.$opModal.show(InfoModal, {
                img: "face_error.svg",
                text: this.$localizationService.getLocalizedValue("app_ErroreImprevistoContattareIlSupporto", "Errore imprevisto, Contattare il supporto!"),
                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    deleteAccount(){
        AppUserClient.deleteAccount()
        .then( x => {
            CommonServices.hideSpinner();
            LoginServices.logout()
            .then( x => {
                this.$opModal.closeAll();
                this.$router.push("/");
            })
        }).catch(err => {
            CommonServices.hideSpinner();
            this.$opModal.show(InfoModal, {
                img: "face_error.svg",
                text: this.$localizationService.getLocalizedValue("app_ErroreImprevistoContattareIlSupporto", "Errore imprevisto, Contattare il supporto!"),
                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }
}
