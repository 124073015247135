
import { Options, Vue } from 'vue-class-component';
import { AppUserClient } from '@/services/Services';
import { Watch } from 'vue-property-decorator';
import InfoModal from '@/components/modals/infoModal.vue';
import ReferralCodeButton from '@/components/referralCodeButton.vue';
import InfoCompleteProfileModal from '@/components/modals/InfoCompleteProfileModal.vue';
import ProfileImageHandle from '../components/profileImageHandle.vue';
import PreviewProfileModal from '../modals/previewProfileModal.vue';
import viewDescriptionModal from '../modals/viewDescriptionModal.vue';
import SettingsModal from '../modals/profileSettings/settingsModal.vue';
import * as OM from '@/Model';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';

@Options({
    components: {
        ProfileImageHandle,
        ReferralCodeButton
    }
})
export default class Profile extends Vue {

    tempMinimumAffinityPercentage: number = 50;
    profile: OM.ProfileEditVM = new OM.ProfileEditVM();

    created(){
        this.init();
    }

    init(){
        Promise.all([
            AppUserClient.getProfileEdit(),
        ])
        .then( xs => {
            this.profile = xs[0];
            this.tempMinimumAffinityPercentage = this.profile.profileData.minimumAffinityPercentage;
        }).catch( err => {
            var message = this.$localizationService.getLocalizedValue("app_QualcosaEAndatoStortoDuranteIlCaricamento", "Qualcosa è andato storto durante il caricamento!");

            if(err.Message)
                message = err.Message;

            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: message,
                confirm: this.$localizationService.getLocalizedValue("app_Riprova", "Riprova"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                    this.init();
                },
                deny: this.$localizationService.getLocalizedValue("app_Indietro", "Indietro"),
                denyCb: () => {
                    this.$opModal.closeLast();
                    this.$router.back();
                },
            })
        })
    }

    timeoutHandler;
    @Watch("tempMinimumAffinityPercentage")
    minimumAffinityChanged(){
        clearTimeout(this.timeoutHandler);
        
        if(this.tempMinimumAffinityPercentage == this.profile.profileData.minimumAffinityPercentage)
            return;

        this.timeoutHandler = setTimeout(() => {
            AppUserClient.updateMinimumAffinityPercentage(this.tempMinimumAffinityPercentage, false)
            .then( x => {
                this.profile.profileData.minimumAffinityPercentage = this.tempMinimumAffinityPercentage;
            })
        }, 1000);
    }

    openCompleteProfileModal(){
        this.$opModal.show(InfoCompleteProfileModal, {
            onboardingpath: false,
        })
    }

    canClickPreview = true;
    async previewProfile(){
        if(!this.canClickPreview)
            return;

        if(this.profile.questions.length == 0){
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("app_AggiungiAlmenoUnaDomandaPrimaDiPoterVisualizzareLAnteprima", "Aggiungi almeno una domanda prima di poter visualizzare l'anteprima!"),
                confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                },
            })

            return;
        }

        this.canClickPreview = false;
        var match = this.getTempMatch();
        var myImages = await AppUserClient.getMyImagesWithAllPercentage(false);

        this.$opModal.show(PreviewProfileModal, {
            match: match,
            questions: this.profile.questions,
            noBlurQuestionImageUrls: this.profile.noBlurQuestionImageUrls,
            previewImages: myImages
        }, () => {
            this.canClickPreview = true;
        })
    }

    getTempMatch(){
        var match = new OM.MatchDetailVm();
        match.birthDate = this.profile.personalData.birthDate;
        match.name = this.profile.personalData.name;
        match.sexualOrientation = this.profile.profileData.sexualOrientation;
        match.minimumAffinityRate = this.profile.profileData.minimumAffinityPercentage;
        match.biography = this.profile.profileData.biography;

        return match;
    }

    openDescription(){
        this.$opModal.show(viewDescriptionModal, {
            ownDescription: true,
            editMode: true,
            completeName: this.profile.personalData.name,
            birthData: this.profile.personalData.birthDate,
            meters: this.profile.searchPreferences.radiusMeters,
            sexualOrientation: this.profile.profileData.sexualOrientation,
            description: this.profile.profileData.biography,
            reload: () => {
                this.init();
            }
        })
    }

    openSettings(){
        this.$opModal.show(SettingsModal, {
            profile: this.profile,
            reload: () => {
                this.init();
            }
        })
    }

    setProfileOnHoliday($event: any) {
        this.profile.profileOnHoliday = !$event;

        AppUserClient.setProfileOnHoliday(this.profile.profileOnHoliday, false)
        .then(x => {
            store.state.loggedUser.profileOnHoliday = this.profile.profileOnHoliday;
            StorageServices.setLoggedUser(store.state.loggedUser);
        });

        if(this.profile.profileOnHoliday) {
            this.$opModal.show(InfoModal, {
                title: this.$localizationService.getLocalizedValue("app_Attenzione", "Attenzione"),
                subtitle: this.$localizationService.getLocalizedValue("app_ProfileOnHolidayText", "Stai mettendo il tuo profilo in modalità “vacanza”. <br />Questo significa che non verrai trovato nel finder dagli altri profili e tu stesso non potrai cercare nuove affinità"),
                confirm: this.$localizationService.getLocalizedValue("app_HoCapito", "Ho capito"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })
        }
    }
}
