
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { AppUserClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from "@/Model";

@Options({
    components: {
    }
})
export default class NotificationsSettingsModal extends Vue {

    @Prop() callback: any;
    @Prop() profile: OM.ProfileEditVM;

    settings: OM.UpdateSettingsVM = new OM.UpdateSettingsVM();

    created(){
        this.settings = {
            gender: this.profile.profileData.gender,
            showAge: this.profile.profileData.showAge,
            emailAddress: this.profile.emailAddress,
            mailing: this.profile.mailNotification,
            notification: this.profile.smsNotification,
            showSexualOrientation: this.profile.profileData.showSexualOrientation,
            sexualOrientation: this.profile.profileData.sexualOrientation,
            experienceCategoriesNotification: this.profile.experienceCategoriesNotificationIds
        };
    }

    save() {
        AppUserClient.updateSettings(this.settings)
        .then( x => {
            this.callback();
        }).catch(err => {
            this.$opModal.show(InfoModal, {
                img: "face_error.svg",
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    disabledNotification(disabled: boolean){
        this.settings.notification.disabled = disabled;

        if(disabled){
            this.settings.notification.newMatch = false;
            this.settings.notification.newMessage = false;
            this.settings.notification.promotion = false;
            this.settings.notification.affinityRemoved = false;
            this.settings.notification.ghostingAlert = false;
            return;
        }

        this.settings.notification.newMatch = true;
        this.settings.notification.newMessage = true;
        this.settings.notification.promotion = true;
        this.settings.notification.affinityRemoved = true;
        this.settings.notification.ghostingAlert = true;
    }

    disableMailing(disabled: boolean){
        this.settings.mailing.disabled = disabled;

        if(disabled){
            this.settings.mailing.newMatch = false;
            this.settings.mailing.newMessage = false;
            this.settings.mailing.promotion = false;
            this.settings.mailing.affinityRemoved = false;
            this.settings.mailing.ghostingAlert = false;
            return;
        }

        this.settings.mailing.newMatch = true;
        this.settings.mailing.newMessage = true;
        this.settings.mailing.promotion = true;
        this.settings.mailing.affinityRemoved = true;
        this.settings.mailing.ghostingAlert = true;
    }
}
