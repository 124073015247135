import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal full bg_white" }
const _hoisted_2 = { class: "modal_header" }
const _hoisted_3 = { class: "modal_body p-0" }
const _hoisted_4 = { class: "swiper-slide" }
const _hoisted_5 = { class: "modal_footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_image_and_questions_preview = _resolveComponent("image-and-questions-preview")
  const _component_view_profile = _resolveComponent("view-profile")
  const _component_localized_text = _resolveComponent("localized-text")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_header_feven, { white: false })
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_view_profile, {
        match: _ctx.match,
        questions: _ctx.questions,
        affinity: _ctx.match.minimumAffinityRate + '%'
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.questions, (item, index) => {
            return (_openBlock(), _createBlock("div", _hoisted_4, [
              _createVNode(_component_image_and_questions_preview, {
                profileQuestion: item,
                image: _ctx.noBlurQuestionImageUrls[index],
                previewImages: _ctx.getPreviewImages(index)
              }, null, 8, ["profileQuestion", "image", "previewImages"])
            ]))
          }), 256))
        ]),
        _: 1
      }, 8, ["match", "questions", "affinity"])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("button", {
        class: "fev_button bg_brand",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_Chiudi",
          text: "Chiudi"
        })
      ])
    ])
  ]))
}