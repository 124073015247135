
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/components/standard/formFieldPlugin/mixins/withModel';

@Options({
    components: {
    }
})
export default class newsletterMoreUsersInMyAreaModal extends mixins(WithModel) {

    next(accept: boolean){
        this.localValue.newsletterMoreUsersInMyArea = accept;
        this.$emit("next");
    }
}
