
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({
    components: {}
})
export default class PreviewProfileModal extends Vue {

    @Prop() questions: OM.ProfileQuestion[];
    @Prop() noBlurQuestionImageUrls: string[];
    @Prop() match: OM.MatchDetailVm;

    @Prop({
        default: () => []
    }) previewImages: string[];

    getPreviewImages(index: number){
        if(this.previewImages.length == 0)
            return [];

        var photoIndex = index * 4;
        return this.previewImages.slice(photoIndex, index * 4 + 4);
    }
    
}
