
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import reasonModal from './Steps/reasonModal.vue';
import tipsModal from './Steps/tipsModal.vue';
import newsletterFutureUpdateModal from './Steps/newsletterFutureUpdateModal.vue';
import newsletterMoreUsersInMyAreaModal from './Steps/newsletterMoreUsersInMyAreaModal.vue';
import deleteAccountQuestionModal from './Steps/deleteAccountQuestionModal.vue';

@Options({
    components: {
    }
})
export default class DeleteAccountModal extends Vue {

    @Prop() deleteAccount: (model: OM.DeleteAccountReason) => void;
    reason: OM.DeleteAccountReason = new OM.DeleteAccountReason();
    step: number = 0;

    get component(){
        if(this.step == 0)
            return reasonModal;
        else if(this.step == 1)
            return tipsModal;
        else if(this.step == 2)
            return newsletterFutureUpdateModal;
        else if(this.step == 3)
            return newsletterMoreUsersInMyAreaModal;
        
        return deleteAccountQuestionModal;
    }

    stepNext(){
        if(this.step < 4)
            this.step ++;
        else 
            this.deleteAccount(this.reason);
    }

}
