
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class deleteAccountInfoModal extends Vue {

    @Prop() next: () => void;
}
