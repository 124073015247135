
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({})
export default class WriteQuestion extends Vue {

    @Prop() modelValue: OM.ProfileQuestion;
    @Prop() imageNotBlur: string;

}
