
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppUserClient } from '@/services/Services';
import ActivateBombModal from '../activateBombModal.vue';
import DisactivateBombModal from '../disactivateBombModal.vue';
import * as OM from "@/Model";

@Options({})
export default class writeAnswer extends Vue {

    @Prop() modelValue: OM.ProfileQuestion;
    @Prop() image: string;
    @Prop() index: number;
    @Prop() isAlreadyBlurred;

    isBombActive: boolean = false;

    created() {
        this.isBombActive = this.modelValue.minPercentage != null;
    }

    get getClass(){
        if(this.isAlreadyBlurred)
            return "";

        return "blur_" + this.getAffinity;
    }

    get getAffinity(){
        return 100 - (25 * this.index)
    }

    openBombInfoModal() {
        if(!this.isBombActive) {
            AppUserClient.checkIfBombAlreadyUsed(false)
            .then(x => {
                if(x)
                    this.$emit('overrideBomb');
                    
                this.$opModal.show(ActivateBombModal, {
                    overrideLastBomb: x,
                    activateBomb: () => {
                        this.isBombActive = true;
                        this.modelValue.minPercentage = 50;
                        this.$opModal.closeLast();
                    },
                    avoid: () => {
                        this.$opModal.closeLast();
                    }
                })
            })
        }
        else {
            this.$opModal.show(DisactivateBombModal, {
                disactivateBomb: () => {
                    this.isBombActive = false;
                    this.modelValue.minPercentage = null;
                    this.$opModal.closeLast();
                },
                avoid: () => {
                    this.$opModal.closeLast();
                }
            })
        }
    }
}
