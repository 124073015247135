
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/components/standard/formFieldPlugin/mixins/withModel';

@Options({
    components: {
    }
})
export default class deleteAccountQuestionModal extends Vue {

}
