import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/arrow_left_blue.svg'
import _imports_1 from '@/assets/img/close_x_blue.svg'


const _hoisted_1 = { class: "modal full bg_white" }
const _hoisted_2 = { class: "modal_header" }
const _hoisted_3 = { class: "swiper-slide" }
const _hoisted_4 = { class: "modal_footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_write_question = _resolveComponent("write-question")
  const _component_write_answer = _resolveComponent("write-answer")
  const _component_image_and_questions_preview = _resolveComponent("image-and-questions-preview")
  const _component_view_profile = _resolveComponent("view-profile")
  const _component_localized_text = _resolveComponent("localized-text")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_header_feven, { white: false }, {
        left: _withCtx(() => [
          (_ctx.step != 1)
            ? (_openBlock(), _createBlock("img", {
                key: 0,
                src: _imports_0,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.step --))
              }))
            : _createCommentVNode("", true)
        ]),
        right: _withCtx(() => [
          _createVNode("img", {
            src: _imports_1,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          })
        ]),
        _: 1
      })
    ]),
    _createVNode("div", {
      class: ["modal_body", {'flex_align_centered column' : _ctx.step != 6, 'p-0' : _ctx.step == 6}]
    }, [
      (_ctx.step == 1)
        ? (_openBlock(), _createBlock(_component_write_question, {
            key: 0,
            imageNotBlur: _ctx.image,
            modelValue: _ctx.localQuestion,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.localQuestion = $event))
          }, null, 8, ["imageNotBlur", "modelValue"]))
        : (_ctx.step > 1 && _ctx.step < 6)
          ? (_openBlock(), _createBlock(_component_write_answer, {
              key: 1,
              image: _ctx.answerImage,
              modelValue: _ctx.localQuestion,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.localQuestion = $event)),
              isAlreadyBlurred: _ctx.isAnswerImageBlurred,
              onOverrideBomb: _cache[5] || (_cache[5] = ($event: any) => (_ctx.overrideBomb())),
              index: _ctx.step - 2
            }, null, 8, ["image", "modelValue", "isAlreadyBlurred", "index"]))
          : _createCommentVNode("", true),
      (_ctx.step == 6)
        ? (_openBlock(), _createBlock(_component_view_profile, {
            key: 2,
            match: _ctx.match,
            questions: _ctx.allQuestions,
            affinity: _ctx.match.minimumAffinityRate + '%'
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.allQuestions, (item, index) => {
                return (_openBlock(), _createBlock("div", _hoisted_3, [
                  _createVNode(_component_image_and_questions_preview, {
                    profileQuestion: item,
                    image: _ctx.allNoBlurQuestionImageUrls[index],
                    previewImages: _ctx.getPreviewImages(index)
                  }, null, 8, ["profileQuestion", "image", "previewImages"])
                ]))
              }), 256))
            ]),
            _: 1
          }, 8, ["match", "questions", "affinity"]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode("div", _hoisted_4, [
      _createVNode("button", {
        class: "fev_button bg_brand",
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args))),
        disabled: _ctx.isDisabled
      }, [
        (_ctx.step == 5)
          ? (_openBlock(), _createBlock(_component_localized_text, {
              key: 0,
              localizedKey: "app_VisualizzaAnteprima",
              text: "Visualizza Anteprima"
            }))
          : (_ctx.step == 6)
            ? (_openBlock(), _createBlock(_component_localized_text, {
                key: 1,
                localizedKey: "app_Salva",
                text: "Salva"
              }))
            : (_openBlock(), _createBlock(_component_localized_text, {
                key: 2,
                localizedKey: "app_Avanti",
                text: "Avanti"
              }))
      ], 8, ["disabled"])
    ])
  ]))
}