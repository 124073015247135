
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class DisactivateBombModal extends Vue {

    @Prop() disactivateBomb: any;
    @Prop() avoid: any;

    created() {
    }

}
